// Types
import { RootState } from '../store';

const getPostsCategories = (state: RootState) =>
  state.postsCategories.postsCategories;

const postsCategoriesSelectors = {
  getPostsCategories,
};

export default postsCategoriesSelectors;
