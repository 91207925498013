import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPostsMetric } from './metricsTypes';

const initialState: {
  postsMetrics: IPostsMetric[];
} = {
  postsMetrics: [],
};

export const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    setPostsMetrics: (state, action: PayloadAction<IPostsMetric[]>) => {
      state.postsMetrics = action.payload;
    },
    resetMetricsData: state => {
      Object.assign(state, initialState);
    },
  },
});

export const { resetMetricsData, setPostsMetrics } = metricsSlice.actions;

export default metricsSlice.reducer;
