// Types
import { RootState } from '../store';

const getPostsMetrics = (state: RootState) => state.metrics.postsMetrics;

const metricsSelectors = {
  getPostsMetrics,
};

export default metricsSelectors;
