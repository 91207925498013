// react
import React, { FC, useEffect, useState } from 'react';

// Redux
import { useUpdatePostCategoryByIdMutation } from '../../../store/postsCategories/postsCategoryApi';
import { useAppDispatch } from '../../../store/hooks';
import { updatePostCategory } from '../../../store/postsCategories/postsCategorySlice';

//custom components
import DeletePostCategoryModal from '../../Modals/DeletePostCategoryModal/DeletePostCategoryModal';
import PostsCategoryModal from '../../../Components/Modals/PostsCategoryModal/PostsCategoryModal';

// icons
import EditFillIcon from 'remixicon-react/EditFillIcon';
import DeleteBin5FillIcon from 'remixicon-react/DeleteBin5FillIcon';

// styles
import styles from './styles.module.scss';

// types
import { IPostCategory } from '../../../store/postsCategories/postsCategoryTypes';

// helpers
import { formatResponseDate } from '../../../Utils';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// Notifications
import { showErrorNotify } from '../../../Components/helpers/showErrorNotify';
import { showSuccessNotify } from '../../../Components/helpers/showSuccessNotify';

interface IProps {
  postCategory: IPostCategory;
  onDeletePostCategory: (postCategoryId: string) => void;
  disableDrag?: boolean;
  isLoadingDeletePostCategory: boolean;
}

const PostCategoryRow: FC<IProps> = ({
  postCategory,
  onDeletePostCategory,
  disableDrag,
  isLoadingDeletePostCategory,
}) => {
  const { title, isActive, posts, createdAt, updatedAt, _id } = postCategory;
  const dispatch = useAppDispatch();

  const [isOpenUpdateCategoryModal, setIsOpenUpdateCategoryModal] =
    useState<boolean>(false);
  const [deletePostCategoryModal, setDeletePostCategoryModal] =
    useState<boolean>(false);

  const [updatePostCategoryRequest, updatePostCategoryData] =
    useUpdatePostCategoryByIdMutation();

  const onDeletePostCategoryHandler = () => {
    onDeletePostCategory(_id);
    setDeletePostCategoryModal(false);
  };

  useEffect(() => {
    const { data, isSuccess, isError, error } = updatePostCategoryData;
    if (data && isSuccess) {
      dispatch(updatePostCategory(data.data.updatedPostCategory));
      showSuccessNotify('Category updated successfully');
    }

    showErrorNotify(isError, error);
  }, [updatePostCategoryData]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.cell}>
          {!disableDrag ? <DragIndicatorIcon /> : ''}
        </div>
        <div className={styles.cell}>{title}</div>
        <div className={styles.cell}>{posts ? posts.length : 0}</div>
        <div className={styles.cell}>{formatResponseDate(createdAt)}</div>
        <div className={styles.cell}>{formatResponseDate(updatedAt)}</div>
        <div className={styles.cell}>{isActive ? 'Active' : 'Not active'}</div>
        <div className={styles.cell}>
          <button
            className={styles.buttonMenu}
            onClick={() => {
              setIsOpenUpdateCategoryModal(true);
            }}
            style={{ marginRight: '2px' }}
          >
            <EditFillIcon />
          </button>
          <button
            className={styles.buttonMenu}
            style={{ marginLeft: 12 }}
            onClick={() => setDeletePostCategoryModal(true)}
          >
            <DeleteBin5FillIcon />
          </button>
        </div>
        <DeletePostCategoryModal
          open={deletePostCategoryModal}
          onClose={() => setDeletePostCategoryModal(false)}
          onDelete={onDeletePostCategoryHandler}
          postCategoryTitle={title}
          isLoadingDeletePostCategory={isLoadingDeletePostCategory}
        />
      </div>

      <PostsCategoryModal
        open={isOpenUpdateCategoryModal}
        onClose={() => {
          setIsOpenUpdateCategoryModal(false);
        }}
        categoryData={{ title, isActive }}
        onSubmit={({ title, isActive }) => {
          updatePostCategoryRequest({
            categoryId: _id,
            categoryData: { title, isActive },
          });
        }}
      />
    </>
  );
};

export default PostCategoryRow;
