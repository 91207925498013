//react
import React, { FC } from 'react';

//mui components
import { FormControlLabel, Switch } from '@mui/material';

interface CustomSwitchInputProps {
  checked: boolean;
  onChange: (param: boolean) => void;
  label: string;
}

const CustomSwitchInput: FC<CustomSwitchInputProps> = ({
  checked,
  onChange,
  label,
}) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={e => onChange(e.target.checked)}
          sx={{
            '&.MuiSwitch-root': {
              height: '20px',
              width: '33px',
              padding: '0px',
              overflow: 'unset',
            },
            '& .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#cbd5e0',
              borderRadius: '50px',
            },
            '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
              opacity: 1,
              backgroundColor: '#0065ff',
            },
            '& .MuiSwitch-switchBase': {
              padding: '0px',
              width: '100%',
              height: '100%',
              transform: 'translateX(-6px)',
              color: '#fff',
            },
            '& .MuiSwitch-switchBase:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              color: '#fff',
              transform: 'translateX(6px)',
            },
            '& .MuiSwitch-switchBase.Mui-checked:hover': {
              backgroundColor: 'transparent',
            },
            '& .MuiSwitch-thumb': {
              width: '18px',
              height: '18px',
              boxShadow:
                '0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.1)',
              border: '1px solid #e2e8f0',
            },
          }}
        />
      }
      label={label}
      labelPlacement="start"
      sx={{
        '&.MuiFormControlLabel-root': {
          marginRight: '0px',
          marginLeft: '0px',
          marginBottom: '12px',
        },
        '& .MuiFormControlLabel-label': {
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: 1.5,
          fontFamily: 'Inter',
          color: '#2d3748',
          marginRight: '10px',
        },
      }}
    />
  );
};

export default CustomSwitchInput;
