import { ProjectNameEnum } from './Utils/constants';

const environment = {
  BASE_URL: process.env.REACT_APP_BASE_URL || 'http://localhost:5001',
  REFRESH_TOKEN_MINUTES: process.env.REACT_APP_REFRESH_TOKEN_MINUTES || 50,
  API_KEY: process.env.REACT_APP_API_KEY || 'API_KEY',
  IS_HIDE_POSTS_NAVIGATION:
    process.env.REACT_APP_IS_HIDE_POSTS_NAVIGATION === 'true',
  PROJECT_NAME:
    (process.env.REACT_APP_PROJECT_NAME as ProjectNameEnum) || undefined,
};

export const isAXSEliteENV =
  environment.PROJECT_NAME === ProjectNameEnum.AXS_ELITE;

export default environment;
