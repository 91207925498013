export enum SortBy {
  BY_TITLE = 'byTitle',
  BY_CREATED_AT = 'byCreatedAt',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum ImageLabelTheme {
  DARK = 'Dark',
  LIGHT = 'Light',
}

export const postLabelTheme = [ImageLabelTheme.LIGHT, ImageLabelTheme.DARK];
