import React, { FC, useEffect, useState } from 'react';

//mui components
import {
  Box,
  Button,
  Grid,
  InputLabel,
  Modal,
  OutlinedInput,
  Typography,
  IconButton,
} from '@mui/material';

//styles
import styles from './styles.module.scss';

//constants
import { addPostsCategoryConstants } from '../../../Utils/constants';

// remixicon
import CloseLineIcon from 'remixicon-react/CloseLineIcon';

// custom components
import CustomSwitchInput from '../../../Components/CustomSwitchInput/CustomSwitchInput';

interface PostsCategoryModalProps {
  open: boolean;
  onClose: () => void;
  categoryData?: {
    title: string;
    isActive: boolean;
  };
  onSubmit: ({ title, isActive }: { title: string; isActive: boolean }) => void;
}

const PostsCategoryModal: FC<PostsCategoryModalProps> = ({
  open,
  onClose,
  categoryData,
  onSubmit,
}) => {
  const [category, setCategory] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);

  const handleClose = () => {
    setCategory('');
    setIsActive(false);
    onClose();
  };

  const handleSave = () => {
    onSubmit({ title: category, isActive });
    handleClose();
  };

  useEffect(() => {
    if (!!categoryData) {
      setCategory(categoryData.title);
      setIsActive(categoryData.isActive);
    }
  }, [categoryData]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ backdropFilter: 'blur(10px)' }}
    >
      <Grid className={styles.modal}>
        <Typography className={styles.modalTitle} variant="h6">
          {!!categoryData ? 'Edit' : 'Add'} Category
        </Typography>
        <Typography className={styles.modalDesc}>
          Organize Content with Categories
        </Typography>
        <Box width="100%" mt="40px">
          <InputLabel className={styles.label}>Category</InputLabel>
          <OutlinedInput
            value={category}
            onChange={e => setCategory(e.target.value)}
            placeholder=""
            fullWidth
            className={styles.input}
          />
        </Box>
        <Box marginTop="20px" width={'100%'}>
          <CustomSwitchInput
            checked={isActive}
            onChange={param => {
              setIsActive(param);
            }}
            label="Active"
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={styles.button}
          onClick={handleSave}
          disabled={
            category.length < addPostsCategoryConstants.minLength ||
            category.length > addPostsCategoryConstants.maxLength
          }
        >
          Save
        </Button>

        <IconButton className={styles.buttonClose} onClick={handleClose}>
          <CloseLineIcon />
        </IconButton>
      </Grid>
    </Modal>
  );
};

export default PostsCategoryModal;
