//react
import React, { useEffect, useState } from 'react';

// mui components
import { Box, Button, Typography } from '@mui/material';

//custom components
import { PostsCategoriesWorkspace } from '../../Components/PostsCategoriesWorkspace/PostsCategoriesWorkspace';
import PostsCategoryModal from '../../Components/Modals/PostsCategoryModal/PostsCategoryModal';

//redux
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import postsCategoriesSelectors from '../../store/postsCategories/postsCategorySelectors';
import {
  useCreateNewPostCategoryMutation,
  useDeletePostCategoryByIdMutation,
  useLazyGetAllPostCategoriesPrivateQuery,
} from '../../store/postsCategories/postsCategoryApi';
import {
  setNewPostCategory,
  setPostsCategories,
} from '../../store/postsCategories/postsCategorySlice';

//notiflix
import { showSuccessNotify } from '../../Components/helpers/showSuccessNotify';
import { showErrorNotify } from '../../Components/helpers/showErrorNotify';

// constants
import { addPostsCategoryConstants } from '../../Utils/constants';
import { SortOrder } from '../../interfaces/requestParams';

//styles
import styles from './styles.module.scss';

export const PostsCategoriesSetup = () => {
  const dispatch = useAppDispatch();
  const postsCategories = useAppSelector(
    postsCategoriesSelectors.getPostsCategories
  );

  const [sortCell, setSortCell] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<SortOrder>(SortOrder.ASC);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenCreateCategoryModal, setIsOpenCreateCategoryModal] =
    useState<boolean>(false);

  // RTK Query
  const [getAllPostCategoriesRequest, getAllPostCategoriesData] =
    useLazyGetAllPostCategoriesPrivateQuery();

  const [createNewCategoryRequest, createNewCategoryData] =
    useCreateNewPostCategoryMutation();

  // TODO: Clear item or fetch new after delete
  const [deletePostCategoryRequest, deletePostCategoryData] =
    useDeletePostCategoryByIdMutation();

  //Handlers

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleScrollMenu = () => {
    handleCloseMenu();
  };

  const handleGetAllCategories = () => {
    getAllPostCategoriesRequest({ page: 1, limit: 100 });
  };

  const handleCreateNewCategory = ({
    title,
    isActive,
  }: {
    title: string;
    isActive: boolean;
  }) => {
    createNewCategoryRequest({ title, isActive });
  };

  const handleDeletePostCategory = async (postCategoryId: string) => {
    await deletePostCategoryRequest(postCategoryId);
  };

  //UseEffects
  useEffect(() => {
    if (anchorEl) {
      window.addEventListener('scroll', handleScrollMenu);
    } else {
      window.removeEventListener('scroll', handleScrollMenu);
    }
  }, [anchorEl]);

  useEffect(() => {
    handleGetAllCategories();
  }, []);

  useEffect(() => {
    const { data, isSuccess, isError, error } = createNewCategoryData;
    if (data && isSuccess) {
      showSuccessNotify('New category created successfully');
      dispatch(setNewPostCategory(data.data.createdPostCategory));
    }

    showErrorNotify(isError, error);
  }, [createNewCategoryData]);

  useEffect(() => {
    const { isSuccess, isError, error } = deletePostCategoryData;
    if (isSuccess) {
      handleGetAllCategories();
      showSuccessNotify('Category deleted successfully');
    }

    showErrorNotify(isError, error);
  }, [deletePostCategoryData]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = getAllPostCategoriesData;
    if (data && isSuccess) {
      dispatch(setPostsCategories(data.data));
    }

    showErrorNotify(isError, error);
  }, [getAllPostCategoriesData]);

  return (
    <Box pb="15px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="27px"
      >
        <Typography variant="h3">Posts Categories</Typography>

        <Button
          variant="contained"
          className={`${styles.button} ${styles.buttonCategory}`}
          onClick={() => setIsOpenCreateCategoryModal(true)}
          disabled={
            postsCategories.length >= addPostsCategoryConstants.categoriesLimit
          }
        >
          Add Category
        </Button>
      </Box>

      <Box className={styles.content}>
        <Box className={styles.workspaceWrapper}>
          {postsCategories.length === 0 ? (
            <Box
              textAlign="center"
              alignContent="center"
              height="44px"
              bgcolor="#fff"
            >
              <Typography variant="subtitle2">No categories added</Typography>
            </Box>
          ) : (
            <PostsCategoriesWorkspace
              initialItems={postsCategories}
              onDeletePostCategory={handleDeletePostCategory}
              isLoadingDeletePostsCategory={deletePostCategoryData.isLoading}
              setSortCell={setSortCell}
              setSortDirection={setSortDirection}
              sortCell={sortCell}
              sortDirection={sortDirection}
              handlerGetAllPostCategories={handleGetAllCategories}
            />
          )}
        </Box>
      </Box>

      <PostsCategoryModal
        open={isOpenCreateCategoryModal}
        onClose={() => {
          setIsOpenCreateCategoryModal(false);
        }}
        onSubmit={({ title, isActive }) => {
          handleCreateNewCategory({ title, isActive });
        }}
      />
    </Box>
  );
};
