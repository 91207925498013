import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

// Redux
import { PostMetricEnum } from '../../store/metrics/metricsTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import metricsSelectors from '../../store/metrics/metricsSelectors';
import { setPostsMetrics } from '../../store/metrics/metricsSlice';

//styles
import styles from './index.module.scss';

//mui components
import { Box, ListItemIcon, Typography } from '@mui/material';

//custom components
import Loader from '../../Components/common/Loader/Loader';

//remixicon
import BarChartLine from 'remixicon-react/BarChartLineIcon';
import FileListLine from 'remixicon-react/FileListLineIcon';

//redux
import { useLazyGetAllMetricsQuery } from '../../store/metrics/metricsApi';

//notify
import { showErrorNotify } from '../../Components/helpers/showErrorNotify';

export const Dashboard = () => {
  const postsMetrics = useAppSelector(metricsSelectors.getPostsMetrics);

  const dispatch = useAppDispatch();

  const [getAllMetricsRequest, getAllMetricsResponse] =
    useLazyGetAllMetricsQuery();

  useEffect(() => {
    getAllMetricsRequest();
  }, []);

  useEffect(() => {
    const { data, isSuccess, isError, error } = getAllMetricsResponse;
    if (data && isSuccess) {
      dispatch(setPostsMetrics(data.data.metrics.postsMetrics));
    }

    showErrorNotify(isError, error);
  }, [getAllMetricsResponse]);

  return (
    <Box>
      <Typography variant="h3" mb="31px">
        Dashboard
      </Typography>
      <Box display="flex" flexWrap="wrap" gap="12px">
        {postsMetrics.map(metric => (
          <Link
            to={
              metric.category.title === PostMetricEnum.ALL_POSTS
                ? '/posts'
                : `/posts/${metric.category.slug}`
            }
            key={metric.category._id}
          >
            <Box
              position="relative"
              display="flex"
              className={styles.metricDataContainer}
            >
              <ListItemIcon
                sx={{ width: '56px', height: '56px' }}
                className={styles.metricIcon}
              >
                {metric.category.title === PostMetricEnum.ALL_POSTS ? (
                  <BarChartLine />
                ) : (
                  <FileListLine />
                )}
              </ListItemIcon>

              <Box ml="18px">
                <Typography className={styles.metricTitle}>
                  {metric.category.title}
                </Typography>

                <Typography className={styles.metricTotal}>
                  {getAllMetricsResponse.isFetching ? (
                    <Loader size={'17px'} color="#5d6b82" />
                  ) : (
                    getAllMetricsResponse.isSuccess && metric.total
                  )}
                </Typography>
              </Box>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
};
