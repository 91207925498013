// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Types
import environment from '../../config';
import { RootState } from '../store';
import {
  ICreateNewCategoryData,
  ICreateNewCategoryResponse,
  IGetAllCategoriesPrivateData,
  IGetAllCategoriesPrivateResponse,
  IGetCategoryByIdResponse,
  IUpdateCategoryByIdData,
  IUpdateCategoryByIdResponse,
} from './postsCategoryTypes';

export const postsCategoryApi = createApi({
  reducerPath: 'postsCategoryApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${environment.BASE_URL}/posts/category`,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = (getState() as RootState).auth.tokens.accessToken;

      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }

      headers.set('x-api-key', environment.API_KEY);

      return headers;
    },
  }),

  endpoints: builder => ({
    createNewPostCategory: builder.mutation<
      ICreateNewCategoryResponse,
      ICreateNewCategoryData
    >({
      query: categoryData => {
        return {
          url: `/`,
          method: 'POST',
          body: categoryData,
        };
      },
    }),

    updatePostCategoryById: builder.mutation<
      IUpdateCategoryByIdResponse,
      IUpdateCategoryByIdData
    >({
      query: ({ categoryId, categoryData }) => {
        return {
          url: `/${categoryId}`,
          method: 'PATCH',
          body: categoryData,
        };
      },
    }),

    getAllPostCategoriesPrivate: builder.query<
      IGetAllCategoriesPrivateResponse,
      IGetAllCategoriesPrivateData
    >({
      query: params => ({
        url: '',
        params,
      }),
    }),

    getPostCategoryById: builder.query<IGetCategoryByIdResponse, string>({
      query: categoryId => `/${categoryId}`,
    }),

    deletePostCategoryById: builder.mutation<any, string>({
      query: categoryId => {
        return {
          url: `/${categoryId}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useCreateNewPostCategoryMutation,
  useUpdatePostCategoryByIdMutation,
  useGetAllPostCategoriesPrivateQuery,
  useLazyGetAllPostCategoriesPrivateQuery,
  useLazyGetPostCategoryByIdQuery,
  useDeletePostCategoryByIdMutation,
} = postsCategoryApi;
