import environment, { isAXSEliteENV } from '../../config';

export type listItem = {
  text: menuTitle;
  path: string;
  activeRoutes: string[];
  children?: Array<{ text: string; path: string; activeRoutes: string[] }>;
};

export enum menuTitle {
  DASHBOARD = 'Dashboard',
  POSTS_CATEGORIES = 'Posts Categories',
  POSTS = 'Posts',
  PAGES = 'Footer',
  USERS = 'Users',
}

const commonMenuItems: listItem[] = [
  { text: menuTitle.DASHBOARD, path: '/', activeRoutes: ['/'] },
  {
    text: menuTitle.USERS,
    path: '/users',
    activeRoutes: ['/users'],
  },
  {
    text: menuTitle.POSTS_CATEGORIES,
    path: '/posts/categories-setup',
    activeRoutes: ['/posts/categories-setup'],
  },
];

const postsMenuItems: listItem[] = [
  {
    text: menuTitle.POSTS,
    path: '/posts',
    activeRoutes: ['/posts'],
  },
];

const pagesMenuItem: listItem = {
  text: menuTitle.PAGES,
  path: '/pages',
  activeRoutes: ['/pages'],
};

export const menuList: listItem[] = [
  ...commonMenuItems,
  ...(environment.IS_HIDE_POSTS_NAVIGATION ? [] : postsMenuItems),
  ...(isAXSEliteENV ? [] : [pagesMenuItem]),
];
