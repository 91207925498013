import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPostCategory } from './postsCategoryTypes';

const initialState: {
  postsCategories: IPostCategory[];
} = {
  postsCategories: [],
};

export const postsCategoriesSlice = createSlice({
  name: 'postsCategories',
  initialState,
  reducers: {
    resetPostsCategories: state => {
      Object.assign(state, initialState);
    },
    setPostsCategories: (state, action: PayloadAction<IPostCategory[]>) => {
      state.postsCategories = action.payload;
    },
    setNewPostCategory: (state, action: PayloadAction<IPostCategory>) => {
      state.postsCategories = [...state.postsCategories, action.payload];
    },
    updatePostCategory: (state, action: PayloadAction<IPostCategory>) => {
      state.postsCategories = state.postsCategories.map(item => {
        if (item._id === action.payload._id) {
          return action.payload;
        }
        return item;
      });
    },
  },
});

export const {
  resetPostsCategories,
  setPostsCategories,
  setNewPostCategory,
  updatePostCategory,
} = postsCategoriesSlice.actions;

export default postsCategoriesSlice.reducer;
