import React, { useEffect, useState } from 'react';

//Router
import { useNavigate, useParams } from 'react-router-dom';

//styles
import styles from './index.module.scss';

//mui components
import { Box, Typography, Button } from '@mui/material';

//custom components
import PostCard from '../../Components/PostCard/PostCard';
import { InfinityScrollWrapper } from '../../Components/wrappers/InfinityScrollWrapper';

//redux
import {
  useDeletePostMutation,
  useLazyGetAllPostsPrivateQuery,
} from '../../store/posts/postsApi';
import { PostType } from '../../interfaces/post';

//notiflix
import { showErrorNotify } from '../../Components/helpers/showErrorNotify';
import { showSuccessNotify } from '../../Components/helpers/showSuccessNotify';

export const Posts = () => {
  const navigate = useNavigate();
  const { slug } = useParams();

  // Save the previous page in sessionStorage
  sessionStorage.setItem('previousPage', window.location.href);

  const [postsData, setPostsData] = useState<PostType[]>([]);
  const [nextPostsPage, setNextPostsPage] = useState<number>(1);

  const postsLimit = 10;

  const [getAllPostsRequest, getAllPostsResponse] =
    useLazyGetAllPostsPrivateQuery();

  const [deletePostRequest, deletePostResponse] = useDeletePostMutation();

  const handleGetAllPosts = (page?: number) => {
    getAllPostsRequest({
      page: page || 1,
      limit: postsLimit,
      categoryIdOrSlug: slug || undefined,
    });
  };

  useEffect(() => {
    handleGetAllPosts();
  }, [slug]);

  const { data } = getAllPostsResponse;
  useEffect(() => {
    const { data, isSuccess, isError, error } = getAllPostsResponse;
    if (data && isSuccess) {
      if (data.page === 1) {
        setPostsData(data.data.posts);
      } else if (data.page === nextPostsPage) {
        setPostsData(prev => [...prev, ...data.data.posts]);
      }

      setNextPostsPage(data.page + 1);
    }

    showErrorNotify(isError, error);
  }, [getAllPostsResponse]);

  useEffect(() => {
    const { data, isSuccess, isError, error } = deletePostResponse;
    if (data && isSuccess) {
      showSuccessNotify('Post successfully deleted');
      handleGetAllPosts();
    }

    showErrorNotify(isError, error);
  }, [deletePostResponse]);

  return (
    <Box>
      <Box
        mb="30px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3">Posts</Typography>

        <Box>
          <Button
            variant="text"
            className={styles.buttonCategory}
            onClick={() => navigate('/posts/categories-setup')}
          >
            Categories setup
          </Button>
          <Button
            variant={'contained'}
            className={styles.addPostButton}
            onClick={() => navigate('/post-creation')}
          >
            Add Post
          </Button>
        </Box>
      </Box>
      {postsData.length ? (
        <InfinityScrollWrapper
          nextPage={nextPostsPage}
          onBottomReach={() => handleGetAllPosts(nextPostsPage)}
          additionConditions={
            !!getAllPostsResponse.data &&
            getAllPostsResponse.data.data.posts.length === postsLimit
          }
          isLoader={getAllPostsResponse.isFetching}
        >
          <Box display="flex" flexWrap="wrap" rowGap="16px" columnGap="24px">
            {postsData.map(post => (
              <PostCard
                post={post}
                key={post._id}
                deletePost={() => deletePostRequest(post._id)}
                isLoadingDeletePost={deletePostResponse.isLoading}
              />
            ))}
          </Box>
        </InfinityScrollWrapper>
      ) : (
        data &&
        data.data.posts.length === 0 && (
          <Box textAlign="center">
            <Typography variant="subtitle1">No posts added</Typography>
          </Box>
        )
      )}
    </Box>
  );
};
