import { IResponse } from '../../interfaces/http';

export interface IPostsMetric {
  category: {
    _id: string;
    title: string;
    slug: string;
    order: number;
  };
  isActive: number;
  total: number;
}

// GetAllMetrics
export interface IGetAllMetricsResponse
  extends IResponse<{
    metrics: {
      postsMetrics: IPostsMetric[];
    };
  }> {}

export enum PostMetricEnum {
  ALL_POSTS = 'allPosts',
}
